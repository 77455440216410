import {BiBookAdd, BiCategory, BiSolidBookContent} from 'react-icons/bi';
import {FaUserAlt} from 'react-icons/fa';
import {HiOutlineChartSquareBar} from 'react-icons/hi';
import {MdOutlineFeedback, MdOutlineLibraryBooks} from 'react-icons/md';

const routesConfig = [
  {
    id: 'analytics',
    title: 'Analytics',
    messageId: 'sidebar.app.dashboard.analytics',
    type: 'item',
    icon: <HiOutlineChartSquareBar />,
    url: '/dashboards/analytics',
  },
  {
    id: 'manage',
    title: 'Manage',
    messageId: 'Manage',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Users',
        messageId: 'Users',
        type: 'item',
        icon: <FaUserAlt />,
        url: '/dashboards/users',
      },
      {
        id: 'courses',
        title: 'courses',
        messageId: 'Courses',
        type: 'item',
        icon: <MdOutlineLibraryBooks />,
        url: '/dashboards/courses',
      },
      {
        id: 'create_courses_content',
        title: 'create_courses_content',
        messageId: 'Create Course',
        type: 'item',
        icon: <BiBookAdd />,
        url: '/dashboards/create',
      },
      {
        id: 'courses_content',
        title: 'courses_content',
        messageId: 'View Content',
        type: 'item',
        icon: <BiSolidBookContent />,
        url: '/dashboards/content',
      },
      {
        id: 'report',
        title: 'Report',
        messageId: 'Feedback',
        type: 'item',
        icon: <MdOutlineFeedback />,
        url: '/dashboards/feedback',
      },
      {
        id: 'category',
        title: 'category',
        messageId: 'Categories',
        type: 'item',
        icon: <BiCategory />,
        url: '/dashboards/categories',
      },
    ],
  },
];
export default routesConfig;
