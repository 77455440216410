import React from 'react';
import {useAuthUser} from '@crema/utility/AuthHooks';
import {Formik} from 'formik';
import * as yup from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {
  FETCH_ERROR,
  FETCH_START,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {useDispatch} from 'react-redux';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {AppInfoView} from '@crema';

const validationSchema = yup.object({
  email: yup.string().email('Invalid email format').required('Required'),
});
const PersonalInfo = () => {
  const dispatch = useDispatch();
  const {user} = useAuthUser();
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Formik
        validateOnBlur={true}
        initialValues={{
          ...user,
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting}) => {
          setSubmitting(true);
          dispatch({type: FETCH_START});
          try {
            const res = await jwtAxios.patch('admin/updateProfile', {
              fullName: data.displayName,
              dp: data.profileID,
            });
            if (res.status == 200) {
              localStorage.setItem('JWTFluospeak', res.data?.data?.accessToken);
              dispatch({type: SHOW_MESSAGE, payload: 'Updated Successfully'});
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }
          } catch (error) {
            console.log('error ==>', error.response);
            dispatch({
              type: FETCH_ERROR,
              payload: error.response?.data?.error[0] || error.response?.msg,
            });
          }
          setSubmitting(false);
        }}
      >
        {({values, setFieldValue, isSubmitting}) => {
          return (
            <PersonalInfoForm
              values={values}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          );
        }}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

export default PersonalInfo;

PersonalInfo.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.string,
};
