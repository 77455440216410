import {
  SET_ANALYTICS_DATA,
  DATA_RANGE,
  SET_USER_DATA,
  SET_COURSE_LISTING,
  SET_COURSE_DATA,
  SET_COURSE_CONTENT,
  SET_CONTENT_DATA,
  SET_CAT_DATA,
  SET_FEEDBACK_DATA,
} from 'shared/constants/ActionTypes';

const initialState = {
  analyticsData: {},
  userData: null,
  dataRange: 'All',
  courseListing: [],
  courseContent: {},
  courseData: {},
  contentData: {},
  catData: {},
  feedbackData: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_RANGE:
      return {
        ...state,
        dataRange: action.payload,
      };

    case SET_COURSE_LISTING:
      return {
        ...state,
        courseListing: action.payload,
      };

    case SET_COURSE_DATA:
      return {
        ...state,
        courseData: action.payload,
      };

    case SET_COURSE_CONTENT:
      return {
        ...state,
        courseContent: action.payload,
      };

    case SET_CONTENT_DATA:
      return {
        ...state,
        contentData: action.payload,
      };

    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    case SET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: action.payload,
      };

    case SET_CAT_DATA:
      return {
        ...state,
        catData: action.payload,
      };

    case SET_FEEDBACK_DATA:
      return {
        ...state,
        feedbackData: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
