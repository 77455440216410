import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_ANALYTICS_DATA,
  SET_COURSE_CONTENT,
  SET_COURSE_LISTING,
  SET_FEEDBACK_DATA,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';

export const getAnalytics = (range) => {
  return async (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      const res = await jwtAxios.get(
        `admin/getAnalytics?range=${range.toLowerCase()}`,
      );
      dispatch({type: SET_ANALYTICS_DATA, payload: res.data});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      dispatch({type: FETCH_ERROR});
    }
  };
};

export const getCoursesListing = () => {
  return async (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      const res = await jwtAxios.get(`course?all=true`);
      dispatch({type: SET_COURSE_LISTING, payload: res.data.data});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      dispatch({type: FETCH_ERROR});
    }
  };
};

export const getCourseContent = (courseID) => {
  return async (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      const res = await jwtAxios.get(`course-content/${courseID}`);
      dispatch({type: FETCH_SUCCESS});
      dispatch({type: SET_COURSE_CONTENT, payload: res.data.data});
    } catch (error) {
      dispatch({type: FETCH_ERROR});
    }
  };
};

export const getFeedbackListing = () => {
  return async (dispatch) => {
    try {
      dispatch({type: FETCH_START});
      const res = await jwtAxios.get(`feedback`);
      dispatch({type: SET_FEEDBACK_DATA, payload: res.data.data});
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      dispatch({type: FETCH_ERROR});
    }
  };
};
