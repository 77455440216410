//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

// COURSES
export const SET_COURSE_LISTING = 'set_course_listing';
export const SET_COURSE_DATA = 'set_course_data';
export const SET_COURSE_CONTENT = 'set_course_content';

// CONTENT
export const SET_CONTENT_DATA = 'set_content_data';

// FEEDBACK
export const SET_FEEDBACK_DATA = 'set_feedback_data';

// CATEGORY
export const SET_CAT_DATA = 'set_cat_data';

// DATA FETCH RANGE
export const DATA_RANGE = 'data_range';

//ANALYTICS-DASHBOARD
export const SET_ANALYTICS_DATA = 'set_analytics_data';

// MODAL SETTING
export const SHOW_MODAL = 'show_modal';
export const SHOW_DELETE_MODAL = 'show_delete_modal';

// USER-DASHBOARD
export const SET_USER_DATA = 'set_user_data';
