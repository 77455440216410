import React from 'react';
import {Box, Typography} from '@mui/material';
import IntlMessages from '@crema/utility/IntlMessages';
import {Fonts} from '../../../../shared/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import {Formik} from 'formik';
import * as yup from 'yup';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import jwtAxios from '@crema/services/auth/jwt-auth';
import {AppInfoView} from '@crema';

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  newPassword: yup
    .string()
    .required('New password required.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  retypeNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: {xs: 3, lg: 5},
        }}
      >
        <IntlMessages id='common.changePassword' />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          retypeNewPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, {setSubmitting, resetForm}) => {
          setSubmitting(true);
          dispatch({type: FETCH_START});
          try {
            const res = await jwtAxios.patch('admin/changePassword', {
              oldPassword: data.oldPassword,
              password: data.newPassword,
              confirmPassword: data.retypeNewPassword,
            });
            if (res.status == 200) {
              dispatch({
                type: SHOW_MESSAGE,
                payload: 'Password Changed Successfully',
              });
              resetForm();
            }
          } catch (error) {
            console.log('error ==>', error.response);
            dispatch({type: FETCH_ERROR, payload: error.response.data.msg});
          }
          setSubmitting(false);
        }}
      >
        {({isSubmitting}) => <ChangePasswordForm isSubmitting={isSubmitting} />}
      </Formik>
      <AppInfoView />
    </Box>
  );
};

export default ChangePassword;
