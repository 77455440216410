import React from 'react';

const Signin = React.lazy(() => import('./Signin'));
const ForgotPassword = React.lazy(() => import('./ForgetPassword'));
const ResetPasswordAwsCognito = React.lazy(() => import('./ResetPassword'));
// const Signup = React.lazy(() => import('./Signup'));
// const ConfirmSignupAwsCognito = React.lazy(() =>
//   import('./ConfirmSignupAwsCognito'),
// );
export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordAwsCognito />,
  },
  // {
  //   path: '/signup',
  //   element: <Signup />,
  // },
  // {
  //   path: '/confirm-signup',
  //   element: <ConfirmSignupAwsCognito />,
  // },
];
