import React from 'react';
import {useThemeContext} from '../../../../utility/AppContextProvider/ThemeContextProvider';
import {Box} from '@mui/material';
import {ReactComponent as Logo} from '../../../../../assets/icon/FluoSpeakLogo.svg';
import {useNavigate} from 'react-router-dom';

const AppLogo = () => {
  const navigate = useNavigate();
  const {theme} = useThemeContext();

  return (
    <Box
      sx={{
        height: {xs: 56, sm: 70},
        width: {xs: 150, sm: 175},
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: {xs: 50, sm: 60},
        },
      }}
      className='app-logo'
      onClick={() => navigate('/')}
    >
      <Logo fill={theme.palette.primary.main} />
    </Box>
  );
};

export default AppLogo;
